import React, { useState } from 'react';
import { SalesOrderStore } from '../../stores/SalesOrderStore';
import { Col, Form, Modal, Row } from 'antd';
import { SalesOrderProps } from '@partsbadger/types';
import { Addresses, AddressForm, AddressProps, is_valid_address } from '@partsbadger/library';

interface AddressFormProps {
    order: SalesOrderProps;
    addresses: AddressProps[];
}
const SalesOrderAddresses = (props: AddressFormProps) => {
    const { order, addresses } = props;
    const [form] = Form.useForm();
    const [showAddNewAddress, setShowAddNewAddress] = useState(false);

    return (
        <>
            <Form form={form} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="shipping_address"
                            label="Shipping Address"
                            rules={[
                                { required: true, message: 'Shipping address is required' },
                                // { validator: is_valid_address },
                            ]}
                            initialValue={{
                                name: order.shipping_name,
                                street: order.shipping_street,
                                city: order.shipping_city,
                                state: order.shipping_state,
                                country: {
                                    code: order.shipping_country,
                                    alpha3: order.shipping_country,
                                    name: order.shipping_country,
                                },
                                zip_code: order.shipping_zip_code,
                            }}
                        >
                            <Addresses
                                addresses={addresses}
                                onAddNewAddress={() => setShowAddNewAddress(true)}
                                onChange={address => {
                                    const payload = {
                                        shipping_name: address.name,
                                        shipping_street: address.street,
                                        shipping_city: address.city,
                                        shipping_state: address.state,
                                        shipping_zip_code: address.zip_code,
                                        shipping_country: address.country?.code,
                                    };
                                    SalesOrderStore.update(order.id, payload);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="billing_address"
                            label="Billing Address"
                            rules={[
                                { required: true, message: 'Billing address is required' },
                                // { validator: is_valid_address },
                            ]}
                            initialValue={{
                                name: order.billing_name,
                                street: order.billing_street,
                                city: order.billing_city,
                                state: order.billing_state,
                                country: {
                                    code: order.billing_country,
                                    alpha3: order.billing_country,
                                    name: order.billing_country,
                                },
                                zip_code: order.billing_zip_code,
                            }}
                        >
                            <Addresses
                                addresses={addresses}
                                onAddNewAddress={() => setShowAddNewAddress(true)}
                                onChange={address => {
                                    const payload = {
                                        billing_name: address.name,
                                        billing_street: address.street,
                                        billing_city: address.city,
                                        billing_state: address.state,
                                        billing_zip_code: address.zip_code,
                                        billing_country: address.country?.code,
                                    };
                                    SalesOrderStore.update(order.id, payload);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Modal
                open={showAddNewAddress}
                onCancel={() => {
                    setShowAddNewAddress(false);
                }}
                footer={[]}
            >
                <AddressForm
                    handleSave={payload => {
                        SalesOrderStore.update(order.id, payload);
                        return SalesOrderStore.saveAddress(order.contact?.id, payload).then(() => {
                            SalesOrderStore.getContactById(order?.contact?.id);
                        });
                    }}
                    handleClose={() => {
                        setShowAddNewAddress(false);
                    }}
                />
            </Modal>
        </>
    );
};

export default SalesOrderAddresses;
