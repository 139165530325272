import React, { useEffect } from 'react';
import { SalesOrderStore } from '../../stores/SalesOrderStore';
import { observer } from 'mobx-react';
import { Card, Divider, Spin } from 'antd';
import NotesList from '../Notes/list';
import CreateNote from '../Notes/CreateNote';

interface Props {
    sales_order_id: number;
}

const SalesOrderNotes: React.FC<Props> = ({ sales_order_id }) => {
    const { data, pagination } = SalesOrderStore.notes;

    const loading = false;

    useEffect(() => {
        // Fetch notes when the component mounts
        // SalesOrderStore.getNotes(sales_order_id, 1, 10);
    }, [sales_order_id]);

    return (
        <Card title="Sales Order Notes" style={{ margin: '20px auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            {loading ? (
                <Spin tip="Loading notes..." style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }} />
            ) : (
                <>
                    <NotesList
                        data={data}
                        pagination={pagination}
                        onPageChange={(page: number, pageSize: number) => {
                            SalesOrderStore.getNotes(sales_order_id, page, pageSize);
                        }}
                    />

                    <Divider style={{ margin: '20px 0' }}>Add a New Note</Divider>

                    <CreateNote
                        // loading={SalesOrderStore.createNoteLoading}
                        onSubmit={async (values: { content: string }) => {
                            await SalesOrderStore.createSalesOrderNote(sales_order_id, {
                                content: values.content,
                                title: 'Note',
                            });
                            // Refresh notes after successful submission
                            SalesOrderStore.getNotes(sales_order_id, 1, 10);
                        }}
                    />
                </>
            )}
        </Card>
    );
};

export default observer(SalesOrderNotes);
