import { action, observable, runInAction, makeObservable } from 'mobx';
import moment from 'moment/moment';
import { get, post } from '@partsbadger/utils';
import {
    IVendorTicket,
    IMessagePayload,
    ITicketMessage,
    ITicketRequestParameteres,
} from '@partsbadger/types/src/staff/list/ITicketChatStaff';
import { message } from 'antd';

interface IPaginationClosedTickets {
    currentPage: number;
    //defaultPageSize: number;
    pageSize: number;
    total: number;
    totalSalesRequest: number;
}

class Ticket {
    isLoading = false;
    lodingClosedTickets = false;
    sentMessage = false; // to know if the message has been sent
    ticketList: IVendorTicket[] = [];
    ticketSalesSupportCount = 0;
    ticketsProductionSupportCount = 0;
    ticketsSalesRequest: IVendorTicket[] = [];
    ticket: IVendorTicket | null = null;
    ticketMessages: any[] = []; // format messagges to show in Sales Order Tool Chat
    // Closed tickets
    closedTicketList: IVendorTicket[] = [];
    closedticketsSalesRequest: IVendorTicket[] = [];
    paginationClosedTickets: IPaginationClosedTickets = {
        currentPage: 1,
        //defaultPageSize: 1,
        pageSize: 10,
        total: 0,
        totalSalesRequest: 0,
    };
    //===================
    totalUnreadMessages = 0;

    totalUnreadMessagesProductionSales = 0;

    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            lodingClosedTickets: observable,
            ticketList: observable,
            ticketSalesSupportCount: observable,
            ticketsProductionSupportCount: observable,
            ticketsSalesRequest: observable,
            ticket: observable,
            ticketMessages: observable,
            // Closed tickets
            closedTicketList: observable,
            paginationClosedTickets: observable,
            //===========================
            totalUnreadMessages: observable,

            // Actions
            getAll: action,
            getTicket: action,
            markAsRead: action,
            sendMessage: action,
            getClosedTickets: action,
            getTotalUnreadMessages: action,
            createTicket: action,
        });
    }

    getAll(params: ITicketRequestParameteres) {
        this.isLoading = true;

        return get(`/vendor/v1/orders/${params.zoho_id}/tickets/`, {
            params: params,
        })
            .then(data => {
                runInAction(() => {
                    //message_type can be (production-sales, sales-support)

                    if (params.message_type === 'sales-support') {
                        this.ticketList = data.results;
                        this.ticketsProductionSupportCount = data.count;
                        //this.pagination.current = params.page;
                        //this.pagination.total = data.count;

                        return true;
                    }

                    this.ticketsSalesRequest = data.results;
                    this.ticketSalesSupportCount = data.count;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }

    getClosedTickets(params: ITicketRequestParameteres) {
        this.lodingClosedTickets = true;

        return get(`/vendor/v1/orders/${params.zoho_id}/tickets/`, {
            params: params,
        })
            .then(data => {
                runInAction(() => {
                    if (params.message_type === 'sales-support') {
                        this.closedTicketList = data.results;
                        this.paginationClosedTickets.currentPage = params.page ?? 1;
                        this.paginationClosedTickets.total = data.count;
                        return true;
                    }

                    this.closedticketsSalesRequest = data.results;
                    this.paginationClosedTickets.totalSalesRequest = data.count;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.lodingClosedTickets = false;
                });
            });
    }

    getTicket(id: number) {
        this.isLoading = true;

        return get(`/vendor/v1/orders/${id}/ticket/`)
            .then(data => {
                runInAction(() => {
                    this.ticket = data;
                    //this.pagination.current = params.page;
                    //this.pagination.total = data.count;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }

    markAsRead(id: number) {
        this.isLoading = true;

        return post(`/vendor/v1/orders/${id}/mark-as-read/`)
            .then(data => {
                runInAction(() => {
                    this.ticket = data;
                    //this.pagination.current = params.page;
                    //this.pagination.total = data.count;
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.isLoading = false;
                });
            });
    }

    sendMessage(payload: IMessagePayload) {
        this.isLoading = false;

        const formData = new FormData();

        formData.append('ticket', payload.ticket.toString());

        if (payload.body) {
            formData.append('body', payload.body);
        }

        if (payload.attachments && payload.attachments.length > 0) {
            formData.append('attachment', payload.attachments[0]);
        }

        if (payload.notify_owner) {
            formData.append('notify_owner', payload.notify_owner.toString());
        }

        post(`/vendor/v1/orders/new-vendor-message/`, formData)
            .then(response => {
                runInAction(() => {
                    const newMessage = buildMessagesToChat(response);
                    this.ticketMessages = [newMessage, ...this.ticketMessages];
                    this.sentMessage = true;
                });
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async createTicket(payload: any, id: string) {
        const response = await post(`/vendor/v1/orders/${id}/new-ticket-products/`, payload);

        return response;
    }

    async getTotalUnreadMessages(zoho_id: string, messageType: string) {
        const params = {
            message_type: messageType,
        };

        return await get(`/vendor/v1/orders/${zoho_id}/total-unread-messages/`, {
            params: params,
        })
            .then(data => {
                runInAction(() => {
                    if (messageType === 'production-sales') {
                        this.totalUnreadMessagesProductionSales = data;

                        return data;
                    }

                    this.totalUnreadMessages = data;

                    return data;
                });

                return data;
            })
            .catch(error => {
                console.error(error);
                // message.error({ content: error.message });
            });
    }
}

// Messages come from vendor app, this component has different fields, it is necessary to format
const buildMessagesToChat = (message: ITicketMessage) => {
    const newMessage = {
        id: message.id,
        body: message.body,
        attachment: message.attachment,
        attachments: [],
        author: {
            fullname: message.author,
        },
        notify_owner: false,
        created_time: moment(message.created).toDate(),
    };

    return newMessage;
};

export const TicketStore = new Ticket();
