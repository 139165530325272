import React, { useState } from 'react';
import { List, Avatar, Typography, Card } from 'antd';
import { INotes, IQualityDocumentsProps, IShipmentProps, VendorProductsProps } from '../../../utils/types';
import { PaginationComponent } from '../Inputs/Pagination';
const { Text } = Typography;

interface NotesProps {
    data: INotes[];
    pagination: {
        total: number;
        pageSize: number;
        current: number;
    };
    onPageChange: (page: number, pageSize: number) => void;
}

const NotesList: React.FC<NotesProps> = ({ data, pagination, onPageChange }) => {
    return (
        <Card title="Notes" bordered style={{ margin: '20px' }}>
            <List
                itemLayout="vertical"
                dataSource={data}
                renderItem={note => (
                    <List.Item key={note.id}>
                        <List.Item.Meta
                            avatar={
                                <Avatar src={note.created_by.picture || undefined}>
                                    {note.created_by.first_name[0]}
                                </Avatar>
                            }
                            title={
                                <Text strong>
                                    Created by: {note.created_by.fullname} ({note.created_by.email})
                                </Text>
                            }
                            description={
                                <Text type="secondary">Created at: {new Date(note.created_time).toLocaleString()}</Text>
                            }
                        />
                        <Text>{note.content}</Text>
                    </List.Item>
                )}
            />
            <PaginationComponent
                defaultPageSize={pagination.pageSize}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={onPageChange}
            />
        </Card>
    );
};

export default NotesList;
