import React from 'react';
import { Form, Input, Button, message } from 'antd';

const { TextArea } = Input;

interface CreateNoteProps {
    onSubmit: (values: { content: string }) => Promise<void>;
    loading?: boolean;
}

const CreateNote: React.FC<CreateNoteProps> = ({ onSubmit, loading = false }) => {
    const [form] = Form.useForm();

    const handleFinish = async (values: { content: string }) => {
        try {
            await onSubmit(values);
            message.success('Note created successfully!');
            form.resetFields();
        } catch (error) {
            console.error('Error creating note:', error);
            message.error('Failed to create note. Please try again.');
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item
                label="Note Content"
                name="content"
                rules={[
                    { required: true, message: 'Please enter the note content!' },
                    { max: 500, message: 'Content cannot exceed 500 characters.' },
                ]}
            >
                <TextArea rows={4} placeholder="Enter your note content here..." />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateNote;
