// ActionMenu.tsx
import React from 'react';
import { Dropdown, Menu, Space, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { MenuProps } from 'antd';

interface Action {
    key: string;
    icon: React.ReactNode;
    text: string;
    link?: string;
    onClick?: () => void; // Optional onClick handler
}

interface ActionMenuProps {
    actions: Action[];
}

const ActionMenu: React.FC<ActionMenuProps> = ({ actions }) => {
    const menuItems: MenuProps['items'] = actions.map(action => {
        const isExternal = action.link?.startsWith('http');

        return {
            key: action.key,
            icon: action.icon,
            label: action.onClick ? (
                // If onClick is provided, use a button-like span
                <span onClick={action.onClick} style={{ color: 'inherit', cursor: 'pointer' }}>
                    {action.text}
                </span>
            ) : isExternal ? (
                // External link
                <a
                    href={action.link || '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: action.link ? 'inherit' : '#d9d9d9' }}
                >
                    {action.text}
                </a>
            ) : (
                // Internal link
                <Link to={action.link || '#'} style={{ color: action.link ? 'inherit' : '#d9d9d9' }}>
                    {action.text}
                </Link>
            ),
            disabled: !action.link && !action.onClick, // Disable if no link and no onClick
        };
    });

    return (
        <Dropdown overlay={<Menu items={menuItems} />} trigger={['click']}>
            <Typography.Link>
                <Space>
                    Actions
                    <DownOutlined />
                </Space>
            </Typography.Link>
        </Dropdown>
    );
};

export default ActionMenu;
