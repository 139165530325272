import React, { Fragment, useEffect, useState } from 'react';
import {
    Col,
    Divider,
    Drawer,
    Dropdown,
    Menu,
    Modal,
    Row,
    Table,
    notification,
    Popconfirm,
    Tag,
    Typography,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { get, post } from '../../shared';

import { SalesOrderFilterForm } from '../../components/SalesOrder';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
import { PaginationComponent } from '../../components/Inputs/Pagination';
import { SalesOrderPreview } from '../../components/SalesOrder/SalesOrderPreview';

import { hasPermission, hasGroup } from '../../components/User';

import { DateRender, ErrorRender, Price, UploadRedactedFilesForm } from '@partsbadger/library';
import { Chat } from '@partsbadger/quote-tool';

import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    DollarOutlined,
    DownloadOutlined,
    DownOutlined,
    EyeOutlined,
    FilePdfOutlined,
    LoadingOutlined,
    RedoOutlined,
    SendOutlined,
    StopOutlined,
    ShoppingCartOutlined,
    MinusCircleOutlined,
    SyncOutlined,
    CopyOutlined,
} from '@ant-design/icons';
import { SalesOrderListProps } from '@partsbadger/types';
import Stages from '../../components/Stages';

import RequestOrderAdjustment from '../../components/SalesOrder/RequestOrderAdjustment';
import CloneOrderModal from '../../components/SalesOrder/CloneOrderModal';

import { SalesOrderStore } from '../../stores/SalesOrderStore';

import { remove, useQueryParameters } from '@partsbadger/utils';

import { PaginationProps } from 'antd/es/pagination';

const FileDownload = require('js-file-download');

const { Column } = Table;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

import RequestCancelationHold from 'components/SalesOrder/RequestCancelationHold';
import SendToZohoButton from '../../components/Buttons/SendToZohoButton';
import PullFromZohoButton from '../../components/Buttons/PullFromZohoButton';
import { SalesOrder, SalesOrderRequest } from 'Domain/interfaces/SalesOrders/SalesOrderInterface';
import { observer } from 'mobx-react';

export const OrdersList = observer((props: any) => {
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState<SalesOrder[]>([]);
    const [order, setOrder] = useState<{ id: string; line_items: [] } | null>(null);
    const [previewOrder, setPreviewOrder] = useState<number | null>(null);
    const params = useQueryParameters();

    const searchParams = useQuery();
    const [pagination, setPagination] = useState<PaginationProps>({});

    const [sales_order_notifications, setSalesOrderNotifications] = useState([]);
    const [sales_order_notification_id, setSalesOrderNotificationId] = useState<number>(0);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [showRequestCancelationHoldModal, setShowRequestCancelationHoldModal] = useState<boolean>(false);
    const [showUploadFiles, setShowUploadFiles] = useState<boolean>(false);
    const [showRequestOrderAdjustment, setShowRequestOrderAdjustment] = useState<boolean>(false);
    const [showCloneOrderModal, setShowCloneOrderModal] = useState<boolean>(false);

    const history = useHistory();

    const getOrders = (params = {}) => {
        setLoading(true);
        get('staff/sales-orders/', params)
            .then((response: SalesOrderRequest) => {
                setOrders(response.data.results);
                setPagination({
                    pageSize: 30,
                    total: response.data.count,
                });
            })
            .finally(() => setLoading(false));
    };

    const getRMA = (order_id: number) => {
        setLoading(true);
        post(`staff/sales-orders/${order_id}/rma/`)
            .then((r: any) => {
                history.push(`/inventory/`);
            })
            .finally(() => setLoading(false));
    };
    const getRedo = (order_id: number) => {
        setLoading(true);
        post(`staff/sales-orders/${order_id}/redo/`)
            .then((r: any) => {
                history.push(`/sales-order/${r.data.id}/`);
            })
            .finally(() => setLoading(false));
    };

    const getRemake = (order_id: number) => {
        setLoading(true);
        post(`staff/sales-orders/${order_id}/remake/`)
            .then((r: any) => {
                history.push(`/sales-order/${r.data.id}/`);
            })
            .finally(() => setLoading(false));
    };
    const downloadPdf = (order_id: number, order_name: string) => {
        setLoading(true);

        get(`staff/sales-orders/${order_id}/download/`, {}, { responseType: 'blob' })
            .then((response: any) => {
                const data = response.data;

                FileDownload(data, `${order_name}.pdf`);
                setLoading(false);
                notification.success({ message: 'Document downloaded' });
            })
            .catch((error: any) => notification.error(error));
    };

    const getOrdersById = async (id: number) => {
        setLoading(true);
        await get(`staff/sales-orders/${id}/`)
            .then((response: any) => {
                setOrder(response.data);
            })
            .finally(() => setLoading(false));
    };
    const deleteOrder = (id: number) => {
        setLoading(true);
        remove(`/staff/sales-orders/${id}/`)
            .then((response: any) => {
                getOrders();
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getOrders(searchParams);
    }, [props.location.search]);

    useEffect(() => {
        if (sales_order_notification_id > 0) {
            get(`staff/sales-orders/${sales_order_notification_id}/notifications/`).then((response: any) => {
                setSalesOrderNotifications(response.data);
            });
        }
    }, [sales_order_notification_id]);

    useEffect(() => {
        const show_last_message = Number(params.get('show-last-message'));
        if (show_last_message) setSalesOrderNotificationId(show_last_message);
    }, []);

    const parts = order?.line_items || [];

    const download = (order_id: number, order_name: string) => {
        const url = `staff/sales-orders/${order_id}/download-all/`;

        setDownloading(true);
        get(url, {}, { responseType: 'blob' })
            .then((response: any) => {
                FileDownload(response.data, `${order_id}-${order_name}-all-files.zip`);
                setDownloading(false);
            })
            .catch(() => {
                setDownloading(false);
            });
    };

    const getRequestCancelationOrHoldModal = (orderId: number) => {
        getOrdersById(orderId);
        setShowRequestCancelationHoldModal(true);
    };

    return (
        <Fragment>
            <SalesOrderFilterForm />

            <Divider>
                <div className={'title'}> Sales Orders</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<SalesOrderListProps>
                        rowKey={'id'}
                        dataSource={orders}
                        loading={loading}
                        pagination={false}
                        footer={() => {
                            return <PaginationComponent {...pagination} />;
                        }}
                        scroll={{ x: 'auto' }}
                    >
                        <Column title="Nro" dataIndex="id" key="id" />
                        <Column<SalesOrderListProps>
                            title="Name"
                            dataIndex="name"
                            key="name"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <Typography.Link
                                            disabled={record?.status == 'Draft'}
                                            onClick={() => history.push(`/new-sales-order-detail/${record?.id}/`)}
                                        >
                                            {record?.name}
                                        </Typography.Link>
                                    </div>
                                );
                            }}
                        />
                        <Column<SalesOrderListProps>
                            title="Created Time"
                            dataIndex="created_time"
                            key="created_time"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <div>
                                            <DateRender value={record?.order_submitted_time} />
                                        </div>
                                        <div className={'italic'}>By {record.created_by.fullname}</div>
                                    </div>
                                );
                            }}
                        />

                        <Column title="Source" dataIndex="source" key="source" />

                        <Column<SalesOrderListProps>
                            title="Account"
                            dataIndex="account"
                            key="account"
                            render={(text, record) => {
                                if (record?.account) {
                                    return record?.account.name;
                                }
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Contact"
                            dataIndex="contact"
                            key="contact"
                            render={(text, record) => {
                                if (record.contact) {
                                    return record.contact.fullname;
                                }
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Design Engineer"
                            dataIndex="design_engineer"
                            key="design_engineer"
                            render={(text, record) => {
                                if (record.design_engineer) {
                                    return record.design_engineer.fullname;
                                }
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="In Zoho"
                            dataIndex="zoho_id"
                            key="zoho_id"
                            render={(text, record) => {
                                if (record.zoho_id) {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'left',
                                                width: '100%',
                                            }}
                                        >
                                            <div>
                                                <a
                                                    target={'_blank'}
                                                    rel="noreferrer"
                                                    href={`${ZOHO_BASE_URL}/SalesOrders/${record.zoho_id}`}
                                                >
                                                    View in Zoho
                                                </a>
                                            </div>

                                            <div className={'cursor-pointer ml-2'}>
                                                <PullFromZohoButton
                                                    module={'sales-orders'}
                                                    id={record.id}
                                                    onFinish={() => {
                                                        notification.success({
                                                            duration: 2,
                                                            message: 'Order synced from Zoho successfully',
                                                        });
                                                        getOrders();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                }

                                if (record.zoho_id === null && record.status == 'Ready for Production') {
                                    return (
                                        <div>
                                            <SendToZohoButton
                                                module="sales-orders"
                                                id={record.id}
                                                onFinish={() => {
                                                    getOrders();
                                                }}
                                            />
                                        </div>
                                    );
                                }
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Status"
                            dataIndex="status"
                            key="status"
                            render={(text, record) => {
                                return (
                                    <div className={'flex flex-col w-full'}>
                                        <div>
                                            <Stages stage={record.status} />
                                        </div>
                                        <div className="mt-1">
                                            {record.partsbadger_production ? <Tag color="blue">PB Prod</Tag> : ''}
                                        </div>
                                    </div>
                                );
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Sales Rep"
                            dataIndex="owner"
                            key="owner"
                            render={(text, record) => {
                                return <span>{record.owner.fullname}</span>;
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Grand Total"
                            dataIndex="grand_total"
                            key="grand_total"
                            render={(text, record) => {
                                return <Price value={record.grand_total} />;
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Notifications"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <div
                                            className={'link'}
                                            onClick={() => {
                                                setSalesOrderNotificationId(record.id);
                                            }}
                                        >
                                            Messages
                                        </div>
                                    </div>
                                );
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title={'Actions'}
                            key={'actions'}
                            render={(text, record) => {
                                const frequent_action = (
                                    <>
                                        {record.status != 'Draft' && hasPermission('salesorders.change_salesorder') ? (
                                            <div>
                                                <a
                                                    type="link"
                                                    onClick={() => {
                                                        history.push(`/sales-order/${record.id}/`);
                                                    }}
                                                >
                                                    Review Order
                                                </a>
                                            </div>
                                        ) : null}

                                        {record.status != 'Draft' && (
                                            <div>
                                                <a
                                                    type="link"
                                                    onClick={() => {
                                                        history.push(`/orders/redaction-tool/${record.id}`);
                                                    }}
                                                >
                                                    Redaction Tool
                                                </a>
                                            </div>
                                        )}
                                    </>
                                );
                                const menu = (
                                    <Menu>
                                        {record.status === 'Draft' ? (
                                            <Menu.Item
                                                onClick={async () => {
                                                    history.push(`/sales-order/new/?DraftOrderID=${record.id}`);
                                                }}
                                                icon={<EyeOutlined />}
                                            >
                                                Continue order
                                            </Menu.Item>
                                        ) : (
                                            <>
                                                <Menu.Item
                                                    onClick={() => {
                                                        history.push(`/new-sales-order-detail/${record.id}`);
                                                    }}
                                                    icon={<EyeOutlined />}
                                                >
                                                    Order Details
                                                </Menu.Item>

                                                {/*<Menu.Item*/}
                                                {/*    onClick={() => {*/}
                                                {/*        history.push(`/sales-order-detail/${record.id}`);*/}
                                                {/*    }}*/}
                                                {/*    icon={<EyeOutlined />}*/}
                                                {/*>*/}
                                                {/*    View Order*/}
                                                {/*</Menu.Item>*/}

                                                {hasPermission('salesorders.change_salesorder') && (
                                                    <Menu.Item
                                                        onClick={() => {
                                                            history.push(`/sales-order/${record.id}/`);
                                                        }}
                                                        icon={<CheckCircleOutlined />}
                                                    >
                                                        Review Order
                                                    </Menu.Item>
                                                )}
                                                <Menu.Item
                                                    onClick={async () => {
                                                        if (
                                                            !SalesOrderStore.order?.id ||
                                                            SalesOrderStore.order?.id !== record.id
                                                        ) {
                                                            await SalesOrderStore.getById(record.id);
                                                        }

                                                        SalesOrderStore.setCloneOrder();
                                                        setShowCloneOrderModal(true);
                                                    }}
                                                    icon={<CopyOutlined />}
                                                >
                                                    Clone Order
                                                </Menu.Item>

                                                {hasPermission('salesorders.change_salesorder') && (
                                                    <Menu.Item
                                                        // onClick={() => {
                                                        //     history.push(`/sales-order/${record.id}/approved-sample`);
                                                        // }}
                                                        onClick={async () => {
                                                            if (
                                                                !SalesOrderStore.order?.id ||
                                                                SalesOrderStore.order?.id !== record.id
                                                            ) {
                                                                await SalesOrderStore.getById(record.id);
                                                            }

                                                            setShowRequestOrderAdjustment(true);
                                                        }}
                                                        icon={<CheckOutlined />}
                                                    >
                                                        Submit Sample Approval/Rejection
                                                    </Menu.Item>
                                                )}

                                                {record.zoho_id && (
                                                    <Menu.Item
                                                        // onClick={() => {
                                                        //     history.push(
                                                        //         `/sales-order/${record.id}/request-design-change`
                                                        //     );
                                                        // }}
                                                        onClick={async () => {
                                                            if (
                                                                !SalesOrderStore.order?.id ||
                                                                SalesOrderStore.order?.id !== record.id
                                                            ) {
                                                                await SalesOrderStore.getById(record.id);
                                                            }

                                                            setShowRequestOrderAdjustment(true);
                                                        }}
                                                        icon={<SendOutlined />}
                                                    >
                                                        Request Design Change
                                                    </Menu.Item>
                                                )}

                                                {record.status !== 'Needs Review' && (
                                                    <Menu.Item
                                                        onClick={() => {
                                                            setPreviewOrder(record.id);
                                                        }}
                                                        icon={<FilePdfOutlined />}
                                                    >
                                                        Preview
                                                    </Menu.Item>
                                                )}

                                                {record.status !== 'Needs Review' && (
                                                    <Menu.Item
                                                        onClick={() => {
                                                            downloadPdf(record.id, record.name);
                                                        }}
                                                        icon={<FilePdfOutlined />}
                                                    >
                                                        Download PDF
                                                    </Menu.Item>
                                                )}

                                                <Menu.Item
                                                    onClick={() => {
                                                        history.push(`/sales-orders/${record.id}/dwg`);
                                                    }}
                                                    icon={<FilePdfOutlined />}
                                                >
                                                    DWG
                                                </Menu.Item>

                                                {hasPermission('salesorders.add_salesorder') && (
                                                    <Menu.Item
                                                        onClick={() => download(record.id, record.name)}
                                                        icon={downloading ? <LoadingOutlined /> : <DownloadOutlined />}
                                                    >
                                                        Download all Required Paperwork
                                                    </Menu.Item>
                                                )}

                                                {hasPermission('salesorders.change_salesorder') &&
                                                    record.status === 'Ready for Production' && (
                                                        <Menu.Item
                                                            onClick={() => {
                                                                setLoading(true);
                                                                getOrdersById(record.id);
                                                                setShowUploadFiles(true);
                                                            }}
                                                            icon={<CheckCircleOutlined />}
                                                        >
                                                            Upload Files
                                                        </Menu.Item>
                                                    )}

                                                {record.status !== 'Needs Review' && (
                                                    <Menu.Item
                                                        onClick={() => {
                                                            history.push(`/sales-order/${record.id}/credit-memos`);
                                                        }}
                                                        icon={<DollarOutlined />}
                                                    >
                                                        Credit Memos
                                                    </Menu.Item>
                                                )}
                                                {hasPermission('salesorders.change_salesorder') && (
                                                    <Menu.Item
                                                        onClick={() => {
                                                            getRMA(record.id);
                                                        }}
                                                        icon={<RedoOutlined />}
                                                    >
                                                        RMA
                                                    </Menu.Item>
                                                )}

                                                {hasPermission('salesorders.change_salesorder') && (
                                                    <Menu.Item
                                                        onClick={() => {
                                                            getRedo(record.id);
                                                        }}
                                                        icon={<RedoOutlined />}
                                                    >
                                                        Redo
                                                    </Menu.Item>
                                                )}

                                                {hasPermission('salesorders.change_salesorder') && (
                                                    <Menu.Item
                                                        onClick={() => {
                                                            getRemake(record.id);
                                                        }}
                                                        icon={<RedoOutlined />}
                                                    >
                                                        Remake
                                                    </Menu.Item>
                                                )}

                                                {hasPermission('salesorders.change_salesorder') ||
                                                hasPermission('salesorders.change_own_salesorder') ? (
                                                    <Menu.Item
                                                        onClick={async () => {
                                                            if (
                                                                !SalesOrderStore.order?.id ||
                                                                SalesOrderStore.order?.id !== record.id
                                                            ) {
                                                                await SalesOrderStore.getById(record.id);
                                                            }

                                                            setShowRequestOrderAdjustment(true);
                                                        }}
                                                        icon={<StopOutlined />}
                                                    >
                                                        Request Cancelation or Hold
                                                    </Menu.Item>
                                                ) : null}
                                            </>
                                        )}

                                        {record.zoho_id && (
                                            <Menu.Item
                                                onClick={() => {
                                                    setLoading(true);
                                                    post(`staff/sales-orders/${record.id}/send-to-vendor-app/`)
                                                        .then(() => {
                                                            notification.success({
                                                                message: 'Done',
                                                                description: 'Synced with vendor app',
                                                                placement: 'bottomRight',
                                                                duration: 5,
                                                            });
                                                        })
                                                        .catch((e: any) => {
                                                            notification.error({
                                                                message: 'We could sync with vendor app',
                                                                description: <ErrorRender error={e} />,
                                                                placement: 'bottomRight',
                                                                duration: 5,
                                                            });
                                                        })
                                                        .finally(() => {
                                                            setLoading(false);
                                                        });
                                                }}
                                                icon={<FilePdfOutlined />}
                                            >
                                                Sync With Vendor
                                            </Menu.Item>
                                        )}

                                        {record.status != 'Draft' && (
                                            <Menu.Item
                                                onClick={() => {
                                                    history.push(`/orders/redaction-tool/${record.id}`);
                                                }}
                                                icon={<FilePdfOutlined />}
                                            >
                                                Redaction Tool
                                            </Menu.Item>
                                        )}

                                        {/* {hasPermission('salesorders.order_shop') && ( */}
                                        <Menu.Item
                                            onClick={() => {
                                                history.push(`/sales-orders/order-shop/${record.id}`);
                                            }}
                                            icon={<ShoppingCartOutlined />}
                                        >
                                            Send to Shop
                                        </Menu.Item>
                                        {/* )} */}

                                        {hasPermission('salesorders.delete_salesorder') && !record.zoho_id && (
                                            <Menu.Item icon={<CloseOutlined />}>
                                                <Popconfirm
                                                    placement="topLeft"
                                                    title={'Do you want to remove this order?'}
                                                    onConfirm={() => {
                                                        deleteOrder(record.id);
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    Delete
                                                </Popconfirm>
                                            </Menu.Item>
                                        )}
                                        {hasGroup('Production') &&
                                            !['Shipped in Full', 'Shipped & Paid', 'Completed'].includes(
                                                record.status
                                            ) && (
                                                <Menu.Item
                                                    onClick={() => {
                                                        history.push(
                                                            `/new-sales-order-detail/${record.id}/cancellation`
                                                        );
                                                    }}
                                                    icon={<MinusCircleOutlined />}
                                                >
                                                    Cancel Items
                                                </Menu.Item>
                                            )}
                                        {record.zoho_id && (
                                            <Menu.Item
                                                onClick={() => {
                                                    setLoading(true);
                                                    post(
                                                        `staff/sales-orders/${record.id}/send-requirements-to-vendor-app/`
                                                    )
                                                        .then(() => {
                                                            notification.success({
                                                                message: 'Done',
                                                                description: 'The requirements were sent.',
                                                                placement: 'bottomRight',
                                                                duration: 5,
                                                            });
                                                        })
                                                        .catch((e: any) => {
                                                            notification.error({
                                                                message: 'We could sync with vendor app',
                                                                description: <ErrorRender error={e} />,
                                                                placement: 'bottomRight',
                                                                duration: 5,
                                                            });
                                                        })
                                                        .finally(() => {
                                                            setLoading(false);
                                                        });
                                                }}
                                                icon={<SyncOutlined />}
                                            >
                                                Sync Vendor Requirements
                                            </Menu.Item>
                                        )}
                                    </Menu>
                                );

                                return (
                                    <>
                                        {frequent_action}
                                        <Dropdown overlay={menu}>
                                            <span className={'link flex flex-row items-center'}>
                                                Actions <DownOutlined />
                                            </span>
                                        </Dropdown>
                                    </>
                                );
                            }}
                        />
                    </Table>
                </Col>
            </Row>

            {previewOrder && <SalesOrderPreview order_id={previewOrder} handleClose={() => setPreviewOrder(null)} />}

            <Modal
                open={showUploadFiles}
                onCancel={() => {
                    setOrder(null);
                }}
                footer={[]}
            >
                {order?.id && (
                    <div className={'p-4'}>
                        <UploadRedactedFilesForm
                            parts={parts}
                            onUploadRedactedFile={async (line_item_id, file_type, file) => {
                                const formData = new FormData();
                                const filename = file_type === '2d' ? 'redacted_2d_file' : 'redacted_3d_file';

                                formData.append('line_item', line_item_id);
                                formData.append(filename, file);

                                // await post(`staff/sales-orders/${order.id}/redacted-files/`, formData, {}, true);
                            }}
                        />
                    </div>
                )}
            </Modal>

            {showRequestOrderAdjustment && SalesOrderStore.order?.id && (
                <RequestOrderAdjustment
                    onClose={() => setShowRequestOrderAdjustment(false)}
                    order={SalesOrderStore.order}
                    showRequestOrderAdjustment={showRequestOrderAdjustment}
                />
            )}

            {showCloneOrderModal && SalesOrderStore.order?.id && (
                <CloneOrderModal
                    onClose={() => setShowCloneOrderModal(false)}
                    showCloneOrderModal={showCloneOrderModal}
                />
            )}

            <Modal
                open={showRequestCancelationHoldModal}
                onCancel={() => {
                    setShowRequestCancelationHoldModal(false);
                }}
                footer={[]}
                title="Request Cancelation or Hold"
            >
                <RequestCancelationHold orderData={order} onCancel={() => setShowRequestCancelationHoldModal(false)} />
            </Modal>

            {sales_order_notification_id > 0 && (
                <>
                    <Drawer
                        title="Notifications"
                        placement="right"
                        closable={true}
                        width={600}
                        onClose={() => {
                            setSalesOrderNotificationId(0);
                        }}
                        open={true}
                    >
                        <Row>
                            <Chat
                                height={450}
                                messages={sales_order_notifications}
                                onSendMessage={(payload: any) => {
                                    const formData = new FormData();

                                    if (payload.body) {
                                        formData.append('body', payload.body);
                                    }

                                    payload.attachments.forEach((item: any) => {
                                        formData.append('attachments_files', item);
                                    });

                                    const url = `staff/sales-orders/${sales_order_notification_id}/notifications/`;

                                    post(url, formData, 1, true)
                                        .then((response: any) => {
                                            setSalesOrderNotifications(response.data);
                                        })
                                        .catch((error: any) => notification.error(error));
                                }}
                            />
                        </Row>
                    </Drawer>
                </>
            )}
        </Fragment>
    );
});
