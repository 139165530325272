import React, { useEffect, useState } from 'react';
import { Table, Empty, Typography } from 'antd';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { ColumnProps } from 'antd/es/table';
import { IInvoiceDetailStaff } from '@partsbadger/types';
import { useHistory } from 'react-router-dom';
import { EditOutlined, FileOutlined, FilePdfOutlined } from '@ant-design/icons';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
import ActionMenu from '../../TableActions/TableActions';

interface Props {
    order_id: number;
}

const Invoices = (props: Props) => {
    const history = useHistory();

    const handlePrint = (invoice_url?: string) => {
        if (invoice_url) {
            window.open(invoice_url, '_blank', 'width=800,height=800,left=200,top=200');
        }
    };

    const columns: ColumnProps<IInvoiceDetailStaff>[] = [
        {
            title: 'Invoice Number',
            dataIndex: 'name',
            key: 'name',
            // width: '10%',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link onClick={() => history.push(`/invoices/${record.id}/`)}>
                            {record.name}
                        </Typography.Link>
                    </div>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'invoice_status',
            key: 'invoice_status',
            // align: 'right',
        },
        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            // align: 'right',
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            // align: 'right',
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            key: 'grand_total',
            // align: 'right',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => {
                const actions = [
                    {
                        key: 'download-pdf',
                        icon: <FilePdfOutlined />,
                        text: 'Download PDF',
                        disabled: !record?.invoice_data?.invoice_url,
                        onClick: () => handlePrint(record?.invoice_data?.invoice_url),
                    },
                ];

                return <ActionMenu actions={actions} />;
            },
        },
    ];

    return (
        <Table<IInvoiceDetailStaff>
            rowKey={record => record.id.toString()}
            pagination={false}
            columns={columns}
            className="border"
            dataSource={SalesOrderStore.invoices}
            scroll={window.innerWidth <= 600 ? { x: 600 } : undefined}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span style={{ color: '#000' }}>This order does not have invoices.</span>}
                    />
                ),
            }}
        />
    );
};

export default Invoices;
