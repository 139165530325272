import React, { useEffect, useState } from 'react';
import { Checkbox, Card, Col, Row, Skeleton } from 'antd';
import { groupBy } from 'lodash';
import { ExtraFile, ExtraInput, ExtraInputText } from './ExtraInput';
import { get } from '../../shared/api';

const Option = ({ option, checked, value, files, handleChange }) => {
    let field_input = null;

    if (checked) {
        if (option.field_type === 'input') {
            field_input = (
                <ExtraInput
                    id={option.id}
                    value={value}
                    handleChange={value => {
                        handleChange(checked, value, null);
                    }}
                />
            );
        } else if (option.field_type === 'file') {
            field_input = (
                <ExtraFile
                    id={option.id}
                    value={files}
                    handleChange={files => {
                        handleChange(checked, null, files);
                    }}
                />
            );
        } else if (option.field_type === 'inputtext') {
            field_input = (
                <ExtraInputText
                    id={option.id}
                    value={value}
                    handleChange={value => {
                        handleChange(checked, value, null);
                    }}
                />
            );
        }
    }

    return (
        <Checkbox
            key={option.id}
            value={option.id}
            checked={checked}
            onChange={event => {
                handleChange(event.target.checked, value, null);
            }}
        >
            {option.name} {field_input}
        </Checkbox>
    );
};

const Options = ({ value, onChange }) => {
    const [requirements, setRequirements] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        get(`customer/additional-requirements/`).then(response => {
            const grouped_by = groupBy(response?.data?.results, item => {
                return item.type ? item.type : 'Additional Requirements';
            });
            setRequirements(grouped_by);
            setLoading(false); // Stop loading when data is fetched
        });
    }, []);

    const handleChange = (checked, requirement, val, files) => {
        let new_items = [];

        if (checked) {
            const exist = value.find(item => item.requirement === requirement);

            if (exist) {
                // Replace if exists
                new_items = value.map(item =>
                    item.requirement === requirement
                        ? {
                              requirement: requirement,
                              value: val,
                              files: files,
                          }
                        : item
                );
            } else {
                // Append the new one
                new_items = [
                    ...value,
                    {
                        requirement: requirement,
                        value: val,
                        files: files,
                    },
                ];
            }
        } else {
            // Delete the item
            new_items = value.filter(item => item.requirement !== requirement);
        }

        onChange(new_items);
    };

    const cardItems = Object.keys(requirements)?.map((key, index) => {
        const options = requirements[key]?.map(option => {
            const current = value.find(row => parseInt(row.requirement) === option.id);

            return (
                <Col key={option.id} md={12}>
                    <Option
                        option={option}
                        checked={!!current}
                        value={current?.value}
                        files={current?.files}
                        handleChange={(checked, value, files) => {
                            handleChange(checked, option.id, value, files);
                        }}
                    />
                </Col>
            );
        });

        return (
            <Card key={index} title={key} style={{ marginBottom: '20px' }}>
                <Row gutter={[16, 16]}>{options}</Row>
            </Card>
        );
    });

    return (
        <div style={{ width: '100%' }}>
            {loading ? (
                <Skeleton active paragraph={{ rows: 4 }} /> // Display skeleton while loading
            ) : (
                cardItems
            )}
        </div>
    );
};

export default Options;
