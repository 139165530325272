import React from 'react';
import moment from 'moment';
import { VendorProductsProps } from '../../../../utils/types';
import { observer } from 'mobx-react';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';

interface RenderTrackingCodeProps {
    product_zid: string | null;
}
const TrackingCodeColumn = (props: RenderTrackingCodeProps) => {
    const { product_zid } = props;
    const vendorProducts = SalesOrderStore.products_tracking_numbers;
    const trackingCodes = product_zid ? vendorProducts[product_zid] : [];

    return (
        <>
            {trackingCodes && trackingCodes.length > 0 ? (
                <>
                    {trackingCodes.map((tracking: VendorProductsProps, index: number) => {
                        const tracking_code = tracking.vendor_tracking_code
                            ? tracking.vendor_tracking_code.split('/')[0]
                            : null;
                        return (
                            <>
                                {tracking.vendor_tracking_code !== '' ? (
                                    <>
                                        <div key={index} className={'mt-2'}>
                                            <div>
                                                <b>{index + 1}. </b>
                                                {tracking_code} - As of{' '}
                                                {moment(tracking.vendor_created_time).format('M/D/YYYY')}
                                            </div>
                                            <div>
                                                <a
                                                    title={'Try in DHL'}
                                                    target={'_blank'}
                                                    rel={'noreferrer'}
                                                    href={`https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${tracking_code}`}
                                                >
                                                    DHL
                                                </a>

                                                <a
                                                    title={'Try in FedEx'}
                                                    style={{
                                                        marginLeft: 5,
                                                    }}
                                                    target={'_blank'}
                                                    rel={'noreferrer'}
                                                    href={`https://www.fedex.com/fedextrack/?trknbr=${tracking_code}`}
                                                >
                                                    FEDEX
                                                </a>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className={'mt-2'}>
                                        {tracking.vendor_created_time ? (
                                            <>
                                                <b>{index + 1}. </b>As of{' '}
                                                {moment(tracking.vendor_created_time).format('M/D/YYYY')}
                                            </>
                                        ) : (
                                            'Pending..' + product_zid
                                        )}
                                    </div>
                                )}
                            </>
                        );
                    })}
                </>
            ) : (
                'Pending'
            )}
        </>
    );
};

export default observer(TrackingCodeColumn);
