import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { IContactListStaff } from '@partsbadger/types';
import { ColumnProps } from 'antd/es/table';
import { Col, Row, Table } from 'antd';
import { DateRender } from '@partsbadger/library';
import { observer } from 'mobx-react';
import { ContactStore } from '../../stores/ContacStore';
import { useQueryParameters } from '@partsbadger/utils';
import { PaginationComponent } from '../../components/Inputs/Pagination';
import ActionMenu from '../../components/TableActions/TableActions';
import { FileOutlined, FilePdfOutlined } from '@ant-design/icons';
import { ZOHO_BASE_URL } from '../../constants';
import RenderSearch from '../../pages/RenderSearch';

interface Props {
    account_id: number;
}

const ContactsByAccount = ({ account_id }: Props) => {
    const history = useHistory();

    const searchParams = useQueryParameters();

    const loadData = () => {
        const params = {
            page: parseInt(searchParams.get('page') ?? '1'),
            search: searchParams.get('search') ?? '',
            account: account_id,
        };
        ContactStore.getAll(params);
    };

    useEffect(() => {
        loadData();
    }, [location.search]);

    const columns: ColumnProps<IContactListStaff>[] = [
        {
            title: 'First Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.email}</div>
                    </div>
                );
            },
        },
        {
            title: 'Phone / Mobile',
            dataIndex: 'phone',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.phone}</div>
                        <div>{record.mobile}</div>
                    </div>
                );
            },
        },

        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.owner.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                const actions = [
                    {
                        key: 'view-details',
                        icon: <FileOutlined />,
                        text: 'View Details',
                        link: `/contacts/${record.id}`,
                    },
                    {
                        key: 'view-in-zoho',
                        icon: <FilePdfOutlined />,
                        text: 'View In Zoho',
                        link: `${ZOHO_BASE_URL}/Contacts/${record.zoho_id}`,
                    },
                ];

                return <ActionMenu actions={actions} />;
            },
        },
    ];

    const data = ContactStore.contacts;
    const loading = ContactStore.isLoading;

    return (
        <div>
            <Row>
                <Col span={24}>
                    <RenderSearch />
                </Col>
            </Row>

            <br />

            <Row>
                <Col span={24}>
                    <Table<IContactListStaff>
                        rowKey={'id'}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        footer={() => {
                            return (
                                <PaginationComponent
                                    current={ContactStore.pagination.current}
                                    defaultPageSize={ContactStore.pagination.defaultPageSize}
                                    pageSize={ContactStore.pagination.pageSize}
                                    total={ContactStore.pagination.total}
                                />
                            );
                        }}
                    ></Table>
                </Col>
            </Row>
        </div>
    );
};

export default observer(ContactsByAccount);
