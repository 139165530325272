import React from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { SalesOrderProps } from '@partsbadger/types';
import { AddressProps } from '@partsbadger/library';
import { CalendarOutlined, NumberOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
import SalesOrderAddresses from './SalesOrderAddresses';

interface Props {
    order: SalesOrderProps;
    addresses: AddressProps[];
}
const { Text } = Typography;

const SalesOrderDetailHeader = ({ order, addresses }: Props) => (
    <div className="order-header">
        <Row gutter={[16, 16]} align="middle">
            <Col xs={24} md={12}>
                <Text strong>
                    <NumberOutlined /> PO Number:
                </Text>{' '}
                <Text>{order.purchase_order || '--'}</Text>
            </Col>
            <Col xs={24} md={12}>
                <Text strong>
                    <CalendarOutlined /> Ship Date:
                </Text>{' '}
                <Text>{order.required_ship_date || 'In Review'}</Text>
            </Col>
        </Row>

        <Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>
            <Col xs={24} md={12}>
                <Text strong>
                    <UserOutlined /> Account:
                </Text>{' '}
                <Text>{order.account?.name || '-'}</Text>
            </Col>
            <Col xs={24} md={12}>
                <Text strong>
                    <UserOutlined /> Contact:
                </Text>
                <Text>
                    {order.contact?.fullname || '-'}, {order.contact?.email || '-'}
                </Text>
            </Col>
        </Row>

        <Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>
            <Col xs={24} md={12}>
                <Text strong>
                    <UserOutlined /> Type:
                </Text>{' '}
                <Text>{order.type}</Text>
            </Col>
            <Col xs={24} md={12}>
                <Text strong>
                    <UserOutlined /> Deal:
                </Text>{' '}
                <Text>{order.deal_related?.name || '-'}</Text>
            </Col>
        </Row>

        <Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>
            <Col xs={24} md={12}>
                <Text strong>
                    <WalletOutlined /> Payment Terms:
                </Text>{' '}
                <Text>{order.payment_terms || '-'}</Text>
            </Col>
            <Col xs={24} md={12}>
                <Text strong>
                    <WalletOutlined /> Payment Info:
                </Text>{' '}
                <Text>{order.payment_notes || '-'}</Text>
            </Col>
        </Row>

        <Divider />

        <SalesOrderAddresses order={order} addresses={addresses} />
    </div>
);

export default SalesOrderDetailHeader;
