import React, { useEffect, useState } from 'react';
import { get, post } from '../../shared';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {
    DownloadOutlined,
    DownOutlined,
    EditOutlined,
    EyeOutlined,
    FilePdfOutlined,
    MinusOutlined,
    PlusOutlined,
} from '@ant-design/icons';

import {
    Badge,
    Col,
    Divider,
    Drawer,
    Dropdown,
    Input,
    Menu,
    Modal,
    notification,
    Row,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DateRender, ErrorRender } from '@partsbadger/library';
import { fetchRFQS } from '../../redux/actions/quoteActions';
import Stages from '../../components/Stages';
import { RfqFilterForm } from '../../components/Rfq/form';
import NextRfqButton from '../../components/Rfq/NextRfqButton';
import { hasPermission } from '../../components/User';
import { PaginationComponent } from '../../components/Inputs/Pagination';
import { QuoteStore } from '../../stores';
import { observer } from 'mobx-react';
import { QuotePreview, Chat } from '@partsbadger/quote-tool';

import { FormEditSubmission } from '../../Forms/RFQ';
import { RFQTicker } from './index';

import { useQuery } from '../../Hooks';
import { RFQListProps, QuoteListProps } from '@partsbadger/types';

import FileDownload from 'js-file-download';
import { SalesOrderStore } from '@partsbadger/sales_order_tool';

const { Column } = Table;

interface IStateProps {
    quotes: QuoteListProps;
}

export const RfqList = observer(() => {
    const [previewQuote, setPreviewQuote] = useState<number>(0);
    const [ticker, setTicker] = useState();
    const [noQuoted, setNoQuoted] = useState(0);
    const [editSubmission, setEditSubmission] = useState(false);
    const [recordEdit, setEditRecord] = useState<any>();
    const [downloading, setDownloading] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const [no_quoted_notes, setNo_quoted_notes] = useState('');
    const [quote_notifications, setQuoteNotifications] = useState([]);
    const [rfq_notification_id, setRfqNotificationId] = useState<number>(0);
    const [quote_notification_id, setQuoteNotificationsId] = useState<number>(0);

    const quotes = useSelector((states: IStateProps) => states.quotes);
    const searchParams = useQuery();
    const location = useLocation();
    const history = useHistory();

    const dispatch = useDispatch();

    let pollingInterval: any = null;

    const loadRfqs = () => {
        dispatch(fetchRFQS(searchParams));
    };

    useEffect(() => {
        document.addEventListener('keydown', event => {
            if (event.code === 'Escape') setNoQuoted(0);
        });

        getTicket();
    }, []);

    useEffect(() => {
        const url = `staff/rfq/ticker/`;
        pollingInterval = setInterval(() => {
            get(url)
                .then((response: any) => {
                    setTicker(response.data);
                })
                .catch(() => {
                    clearInterval(pollingInterval);
                });
        }, 20000);
        return () => {
            if (pollingInterval) clearInterval(pollingInterval);
        };
    }, []);

    useEffect(() => {
        loadRfqs();
    }, [location.search]);

    useEffect(() => {
        if (quote_notification_id > 0) {
            get(`staff/quotes/${quote_notification_id}/notifications/`).then((response: any) => {
                setQuoteNotifications(response.data);
            });
        }
    }, [quote_notification_id]);

    useEffect(() => {
        if (rfq_notification_id > 0) {
            get(`staff/rfq/${rfq_notification_id}/notifications/`).then((response: any) => {
                setQuoteNotifications(response.data);
            });
        }
    }, [rfq_notification_id]);

    const getTicket = () => {
        get('staff/rfq/ticker/' + location.search, {}).then((response: any) => {
            setTicker(response.data);
        });
    };

    const download = (rfq: any) => {
        let url = `staff/rfq/${rfq?.id}/download-all/`;

        if (rfq.quote?.id) {
            url = `staff/quotes/${rfq.quote?.id}/download-all/`;
        }

        setDownloading(rfq.id);

        get(url, {}, { responseType: 'blob' })
            .then((response: any) => {
                FileDownload(response.data, `rfq-${rfq.id}-${rfq.account?.name || rfq.account_name}-files.zip`);
                setDownloading(false);
            })
            .catch(() => {
                setDownloading(false);
            });
    };

    const downloadQuote = (rfq: any) => {
        let url = ``;

        if (rfq.quote?.id) {
            url = `staff/quotes/${rfq.quote?.id}/export/`;
        }

        setDownloading(rfq.id);
        quotes.isLoading = true;
        get(url, {}, { responseType: 'blob' })
            .then((response: any) => {
                FileDownload(response.data, `${rfq.quote?.name}.pdf`);
                quotes.isLoading = false;
                setDownloading(false);
            })
            .catch(() => {
                setDownloading(false);
                quotes.isLoading = false;
            });
    };

    const dataSource = quotes?.rfqs;

    return (
        <>
            <ErrorRender error={quotes.error} />

            <RfqFilterForm />

            {hasPermission('quotes.add_quote') && (
                <Divider>
                    <div className={'title'}>
                        RFQ <NextRfqButton />
                    </div>
                </Divider>
            )}

            <Row style={{ textAlign: 'right' }}>
                <RFQTicker ticker={ticker} />
            </Row>

            <Row className={'row-list'}>
                <Col xs={24}>
                    <Table
                        rowKey={'id'}
                        dataSource={dataSource}
                        loading={quotes.isLoading}
                        pagination={false}
                        footer={() => {
                            return <PaginationComponent {...quotes.pagination_rfqs} />;
                        }}
                        scroll={{ x: '1200px' }}
                    >
                        <Column title="#" dataIndex="id" key="id" />
                        <Column<RFQListProps>
                            title="Quote"
                            dataIndex="quote"
                            key="quote"
                            render={(text, record) => {
                                return (
                                    <div>
                                        {record?.quote ? (
                                            <Typography.Link
                                                onClick={() => {
                                                    if (record.quote) {
                                                        history.push(`/quotes/${record.quote.id}/details/`);
                                                    }
                                                }}
                                            >
                                                {record.quote.name}
                                            </Typography.Link>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                );
                            }}
                        />

                        <Column<RFQListProps>
                            title="Contact"
                            dataIndex="contact"
                            key="contact"
                            render={(text, record) => {
                                if (record.contact) {
                                    return (
                                        <div>
                                            {record.contact.fullname} <br />
                                            {record.account ? (
                                                <p
                                                    style={{
                                                        fontStyle: 'italic',
                                                    }}
                                                >
                                                    {record.account_name}
                                                </p>
                                            ) : null}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div>
                                            <Badge count={'Pending'} />
                                            <p>
                                                {record.contact_first_name} {record.contact_last_name}
                                            </p>
                                        </div>
                                    );
                                }
                            }}
                        />
                        <Column<RFQListProps>
                            title="Created Time"
                            dataIndex="created_time"
                            key="created_time"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <DateRender value={record.created_time} />
                                        <div className={'italic'}>By: {record.created_by.fullname}</div>
                                    </div>
                                );
                            }}
                        />

                        <Column<RFQListProps>
                            title="Source"
                            dataIndex="source"
                            key="source"
                            render={(text, record) => {
                                return record.quote?.source;
                            }}
                        />

                        <Column<RFQListProps>
                            title="Status"
                            dataIndex="quote"
                            key="quote"
                            render={(text, record) => {
                                if (record.quote && !record.unquoted) {
                                    return (
                                        <div className={'flex flex-col w-full'}>
                                            {record.quote.initiate_go_team && record.quote?.stage !== 'Completed' ? (
                                                <Tooltip
                                                    title={`Why? ${record.quote.go_team_info?.why}, Need by answer: ${
                                                        record.quote.go_team_info?.need_by_answer || '--'
                                                    }`}
                                                >
                                                    <Badge
                                                        className="pt-2"
                                                        count={'Waiting on Go Team'}
                                                        style={{ backgroundColor: '#1a7703' }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <>
                                                    <div>
                                                        <Stages stage={record.quote?.stage} />
                                                    </div>
                                                    {record.quote?.stage === 'Vendor Quoting' && (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            className={'w-full font-bold capitalize'}
                                                        >
                                                            {record.quote?.vendor_rfq_type}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    );
                                } else if (record.unquoted) {
                                    return (
                                        <span>
                                            <Stages stage={'Unquoted'} />
                                            <div> {record.unquoted_notes}</div>
                                        </span>
                                    );
                                }

                                return <Stages stage={''} />;
                            }}
                        />

                        <Column<RFQListProps>
                            title="Sales Rep"
                            dataIndex="owner"
                            key="owner"
                            render={(text, record) => {
                                return record.owner.fullname;
                            }}
                        />

                        <Column<RFQListProps>
                            title="Design Engineer"
                            dataIndex="design_engineer"
                            key="design_engineer"
                            render={(text, record) => {
                                return record?.quote?.design_engineer?.fullname;
                            }}
                        />

                        <Column<RFQListProps>
                            title="Tags"
                            dataIndex="itar"
                            key="itar"
                            render={(text, record) => {
                                return (
                                    <div className="flex flex-col">
                                        {record.itar && <Badge count={'ITAR'} style={{ backgroundColor: '#4b5320' }} />}
                                        {/*{record.quote?.safe_to_autocomplete && (*/}
                                        {/*    <Badge count={'Auto Green'} style={{ backgroundColor: '#27AE60' }} />*/}
                                        {/*)}*/}
                                        {record.quote?.type === 'Requote' && (
                                            <>
                                                <Badge count={'Requote'} style={{ backgroundColor: '#8E44AD' }} />
                                                {record.quote.initiate_go_team && (
                                                    <Tooltip
                                                        title={`Why? ${
                                                            record.quote.go_team_info?.why
                                                        }, Need by answer: ${
                                                            record.quote.go_team_info?.need_by_answer || '--'
                                                        }`}
                                                    >
                                                        <Badge
                                                            className="pt-2"
                                                            count={'Go Team'}
                                                            style={{ backgroundColor: '#1a7703' }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                        {record.quote?.domestic_quoting && (
                                            <Badge count={'Domestic'} style={{ backgroundColor: '#DAB540' }} />
                                        )}
                                        {record.quote?.customer_supplied_material && (
                                            <Badge count={'Customer Material'} style={{ backgroundColor: '#18adaa' }} />
                                        )}
                                        {record.quote?.has_sheet_metal_items && (
                                            <Badge count={'Sheet Metal'} style={{ backgroundColor: '#43455' }} />
                                        )}
                                    </div>
                                );
                            }}
                        />

                        <Column<RFQListProps>
                            title="Notifications"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <div
                                            className={'link'}
                                            onClick={() => {
                                                if (record.quote?.id) {
                                                    setQuoteNotificationsId(record.quote?.id);
                                                }
                                            }}
                                        >
                                            Messages
                                        </div>
                                    </div>
                                );
                            }}
                        />

                        <Column<RFQListProps>
                            title="Action"
                            key="action"
                            render={(text, record) => {
                                const menu = (
                                    <Menu>
                                        {record.quote?.id && (
                                            <Menu.Item
                                                onClick={() => {
                                                    if (record.quote) {
                                                        setPreviewQuote(record.quote.id);
                                                    }
                                                }}
                                                className="flex items-center"
                                            >
                                                <FilePdfOutlined />
                                                <span>Preview Quote</span>
                                            </Menu.Item>
                                        )}

                                        {record.quote?.id && (
                                            <Menu.Item
                                                onClick={() => {
                                                    downloadQuote(record);
                                                }}
                                                className="flex items-center"
                                            >
                                                <FilePdfOutlined />
                                                <span>Download Quote Pdf</span>
                                            </Menu.Item>
                                        )}

                                        {record.quote?.id && (
                                            <Menu.Item>
                                                <Link
                                                    className="flex items-center"
                                                    to={'/quotes/' + record?.quote?.id + '/'}
                                                >
                                                    <EyeOutlined className="pr-1" />
                                                    <span>Open with Quote Tool</span>
                                                </Link>
                                            </Menu.Item>
                                        )}

                                        {hasPermission('quotes.add_quote') && !record.quote && !record.unquoted && (
                                            <Menu.Item>
                                                <Link
                                                    className="flex items-center"
                                                    to={'/rfq/' + record.id + '/create'}
                                                >
                                                    <PlusOutlined className="pr-1" /> <span>Open with Quote Tool</span>
                                                </Link>
                                            </Menu.Item>
                                        )}

                                        <Menu.Item>
                                            <a
                                                onClick={e => {
                                                    e.stopPropagation();
                                                }}
                                                target={'_blank'}
                                                rel="noreferrer"
                                                href={`https://api.parts-badger.com/redirect-zoho/Deal/${record.quote?.deal}`}
                                                className="flex items-center"
                                            >
                                                <EyeOutlined className="pr-1" /> <span>View Deal</span>
                                            </a>
                                        </Menu.Item>

                                        {!record?.quote?.completed && (
                                            <Menu.Item
                                                onClick={() => {
                                                    if (record.account !== null) {
                                                        setEditRecord(record);
                                                        setEditSubmission(true);
                                                    } else {
                                                        notification.error({
                                                            key: 'form-errors',
                                                            duration: 8,
                                                            message: 'This RFQ does not have a registered account.',
                                                            description:
                                                                'Make sure that the RFQ has an associated account.',
                                                            placement: 'top',
                                                            maxCount: 1,
                                                        });
                                                    }
                                                }}
                                                className="flex items-center"
                                            >
                                                <EditOutlined /> <span>Edit Submission</span>
                                            </Menu.Item>
                                        )}

                                        {record.quote?.id && record.quote?.completed && (
                                            <Menu.Item
                                                className="flex items-center"
                                                onClick={() => {
                                                    if (record.quote) {
                                                        setDuplicating(true);
                                                        QuoteStore.getRequote(record.quote.id)
                                                            .then((r: any) => {
                                                                setDuplicating(false);
                                                                history.push('/get-quote/?uid=' + r.data.uid);
                                                            })
                                                            .catch(() => setDuplicating(false));
                                                    }
                                                }}
                                            >
                                                <PlusOutlined /> <span>Re Quote</span>
                                            </Menu.Item>
                                        )}

                                        {!record.quote?.completed && !record?.unquoted && (
                                            <Menu.Item
                                                onClick={() => setNoQuoted(record.id)}
                                                className="flex items-center"
                                            >
                                                <MinusOutlined />
                                                <span>Mark as no quoted</span>
                                            </Menu.Item>
                                        )}

                                        <Menu.Item
                                            onClick={() => {
                                                download(record);
                                            }}
                                            className="flex items-center"
                                        >
                                            <DownloadOutlined />
                                            <span>{downloading ? 'Downloading' : 'Download all files'}</span>
                                        </Menu.Item>

                                        <Menu.Item>
                                            <a
                                                onClick={e => {
                                                    e.stopPropagation();
                                                }}
                                                target={'_blank'}
                                                rel="noreferrer"
                                                href={
                                                    `https://portal.parts-badger.com/my-account/quotes/` +
                                                    record?.quote?.id +
                                                    `/modifications`
                                                }
                                                className="flex items-center"
                                            >
                                                <EyeOutlined className="pr-1" /> <span>Dynamic quoting</span>
                                            </a>
                                        </Menu.Item>
                                        {record.quote && record.quote?.completed && (
                                            <Menu.Item
                                                className="flex items-center"
                                                onClick={async e => {
                                                    await SalesOrderStore.getOrCreateSalesOrder(record.contact.id).then(
                                                        async () => {
                                                            await SalesOrderStore.update(SalesOrderStore.order_id, {
                                                                quotes: [],
                                                            });
                                                            await SalesOrderStore.update(SalesOrderStore.order_id, {
                                                                contact: record.contact.id,
                                                                quotes: [record.quote?.id],
                                                            });
                                                            history.push('/sales-order/new/');
                                                        }
                                                    );
                                                    SalesOrderStore.getContactById(record.contact.id);
                                                    history.push('/sales-order/new/');
                                                }}
                                            >
                                                <PlusOutlined /> <span>Submit for an Order</span>
                                            </Menu.Item>
                                        )}

                                        {!record.quote && (
                                            <Menu.Item>
                                                <div
                                                    onClick={() => {
                                                        QuoteStore.deleteRFQ(record.id).then(() => {
                                                            loadRfqs();
                                                        });
                                                    }}
                                                >
                                                    <EyeOutlined className="pr-1" />
                                                    <span>Delete</span>
                                                </div>
                                            </Menu.Item>
                                        )}
                                    </Menu>
                                );

                                return (
                                    <Dropdown overlay={menu}>
                                        <span className={'link'}>
                                            Actions <DownOutlined />
                                        </span>
                                    </Dropdown>
                                );
                            }}
                        />
                    </Table>
                </Col>
            </Row>

            {previewQuote > 0 && (
                <QuotePreview
                    quote_id={previewQuote}
                    handleClose={() => {
                        setPreviewQuote(0);
                    }}
                />
            )}

            <Modal
                visible={noQuoted > 0}
                onCancel={() => setNoQuoted(0)}
                onOk={() => {
                    // Prefill
                    post('staff/rfq/' + noQuoted + '/unquoted/', {
                        unquoted_notes: no_quoted_notes,
                    }).then(() => {
                        loadRfqs();
                    });
                    setNoQuoted(0);
                }}
            >
                <Input.TextArea
                    placeholder={'Reason, and press enter or just press enter'}
                    value={no_quoted_notes}
                    onChange={event => setNo_quoted_notes(event.target.value)}
                />
            </Modal>

            <Modal
                visible={editSubmission}
                title={'Edit account, contact and sales representative'}
                onCancel={() => setEditSubmission(false)}
                footer={false}
            >
                {editSubmission && (
                    <FormEditSubmission
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        record={recordEdit}
                        setEditSubmission={(value: any) => {
                            loadRfqs();
                            setEditSubmission(value);
                        }}
                    />
                )}
            </Modal>

            {(quote_notification_id || rfq_notification_id) && (
                <Drawer
                    title="Notifications"
                    placement="right"
                    closable={true}
                    width={600}
                    onClose={() => {
                        setQuoteNotificationsId(0);
                        setRfqNotificationId(0);
                    }}
                    visible={true}
                >
                    <Chat
                        height={450}
                        messages={quote_notifications}
                        onSendMessage={payload => {
                            const formData = new FormData();

                            if (payload.body) {
                                formData.append('body', payload.body);
                            }

                            if (payload.notify_owner) {
                                formData.append('notify_owner', String(payload.notify_owner));
                            }

                            payload.attachments.forEach((item: any) => {
                                formData.append('attachments_files', item);
                            });

                            const url = `staff/${quote_notification_id ? 'quotes' : 'rfq'}/${
                                quote_notification_id || rfq_notification_id
                            }/notifications/`;

                            post(url, formData, 1, true)
                                .then((response: any) => {
                                    setQuoteNotifications(response.data);
                                })
                                .catch((error: any) => console.log(error));
                        }}
                    />
                </Drawer>
            )}
        </>
    );
});
