import React, { useEffect } from 'react';
import { Chat } from '@partsbadger/quote-tool';
import { SalesOrderStore } from '../../stores/SalesOrderStore';
import { post } from '@partsbadger/utils';
import { notification } from 'antd';
import { observer } from 'mobx-react';

interface Props {
    sales_order_id: number;
}
const SalesOrderMessages = ({ sales_order_id }: Props) => {
    return (
        <div>
            <Chat
                height={450}
                messages={SalesOrderStore.notifications}
                onSendMessage={(payload: any) => {
                    const formData = new FormData();

                    if (payload.body) {
                        formData.append('body', payload.body);
                    }

                    payload.attachments.forEach((item: any) => {
                        formData.append('attachments_files', item);
                    });

                    const url = `staff/sales-orders/${sales_order_id}/notifications/`;

                    post(url, formData)
                        .then((response: any) => {
                            SalesOrderStore.getNotifications(sales_order_id);
                        })
                        .catch((error: any) => notification.error(error));
                }}
            />
        </div>
    );
};

export default observer(SalesOrderMessages);
