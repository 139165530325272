import React, { useEffect, useState } from 'react';
import { notification, Table } from 'antd';
import moment from 'moment';

import { get } from '@partsbadger/utils';

import { ICancelProductHistory } from '../../../../../src/stores/types';

type Props = {
    sales_order_id: string;
};

const CancelItemsHistory = ({ sales_order_id }: Props) => {
    const [loading, setLoading] = useState(false);
    const [stateData, setStateData] = useState<ICancelProductHistory[]>([]);

    useEffect(() => {
        getItemsHistory();
    }, [sales_order_id]);

    const columns = [
        {
            title: 'Order line item Name',
            width: 500,
            render: (r: ICancelProductHistory) => {
                return r.sales_order_line_item.name;
            },
        },
        {
            title: 'Quantity Canceled',
            width: 200,
            render: (r: ICancelProductHistory) => {
                return r.cancelled_quantity;
            },
        },
        {
            title: 'Canceled By',
            width: 300,
            render: (r: ICancelProductHistory) => {
                return `${r.sales_order_line_item.owner.first_name} ${r.sales_order_line_item.owner.last_name}`;
            },
        },
        {
            title: 'Cancelation Date',
            width: 200,
            render: (r: ICancelProductHistory) => {
                return moment(r.created_time).format('YYYY-MM-DD');
            },
        },
        {
            title: 'Reason for cancellation',
            render: (r: ICancelProductHistory) => {
                return r.notes;
            },
        },
    ];

    const getItemsHistory = () => {
        const url = `/staff/sales-orders/${sales_order_id}/cancelled-items/`;
        setLoading(true);
        get(url)
            .then(response => {
                setStateData(response);
            })
            .catch(error => {
                notification.error({ message: 'Error getting data.', duration: 5 });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="flex flex-col">
            <Table
                bordered
                rowKey={'id'}
                columns={columns}
                loading={loading}
                dataSource={stateData}
                pagination={false}
            ></Table>
        </div>
    );
};

export default CancelItemsHistory;
