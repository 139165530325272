var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Affix, Alert, Button, Drawer, Modal, notification, Row, Spin } from 'antd';
import { AccountInfo, ApplicationCostItemsReorder, QuoteActions, QuoteHeader, QuoteNotifications, QuotePreview, QuoteStatus, QuoteToolBar, } from './components';
import { ReorderMasterProducts } from './components/MasterProduct/index';
import { AccountStore, QuoteStore } from './Stores';
import { ErrorRender, GenericSelect, DeletionAlert } from '@partsbadger/library';
import { LineItemsBulkEditForm, QuoteEditForm } from './Forms/index';
import { ReArrangeFiles } from './components/LineItems/ReArrangeFiles';
import { useQueryParameters } from '@partsbadger/utils';
import { observer } from 'mobx-react';
import LayoutV2 from './Layouts/LayoutV2';
import LayoutV1 from './Layouts/LayoutV1';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
function get_shortcut(e) {
    return e.ctrlKey && e.shiftKey && e.altKey;
}
var QuoteTool = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useState(true), isLoading = _h[0], setIsLoading = _h[1];
    var _j = useState(false), isExpired = _j[0], setIsExpired = _j[1];
    var _k = useState(false), reArrangeFiles = _k[0], setReArrangeFiles = _k[1];
    var _l = useState(false), previewQuote = _l[0], setPreviewQuote = _l[1];
    var _m = useState(false), showBulkEdit = _m[0], setShowBulkEdit = _m[1];
    var _o = useState([]), selectedLineItems = _o[0], setSelectedLineItems = _o[1];
    var _p = useState(false), showEditForm = _p[0], setShowEditForm = _p[1];
    var _q = useState(false), showQuoteNotificationsDrawer = _q[0], SetShowQuoteNotificationsDrawer = _q[1];
    var _r = useState(false), reordering = _r[0], setReordering = _r[1];
    var _s = useState(false), reordering_app_cost = _s[0], setReorderingAppCost = _s[1];
    var _t = useState(), account = _t[0], setAccount = _t[1];
    var searchParams = useQueryParameters();
    var v = searchParams.get('version') === '2' ? 2 : 1;
    var _u = useState(v), version = _u[0], setVersion = _u[1];
    var _v = useState([]), additional_requirements = _v[0], setAdditionalRequirements = _v[1];
    var params = useParams();
    var history = useHistory();
    var id = params.id;
    var quote = QuoteStore.quote;
    var loadAllData = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, QuoteStore.getQuote(id)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, QuoteStore.getMasterProducts(id)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, QuoteStore.getLineItems(id)];
                case 3:
                    _a.sent();
                    setIsLoading(false);
                    return [4 /*yield*/, QuoteStore.getQuoteAccountOverview(id)];
                case 4:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getStaffUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, QuoteStore.getUsers({
                        is_staff: true,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        getStaffUsers();
    }, []);
    useEffect(function () {
        document.addEventListener('dragover', function (e) {
            e.preventDefault();
        }, false);
        document.addEventListener('drop', function (e) {
            e.preventDefault();
        }, false);
        document.addEventListener('keydown', function (event) {
            var shortcut = get_shortcut(event);
            if (shortcut && event.code === 'KeyA') {
                setSelectedLineItems(QuoteStore.lineItems.map(function (p) { return p.id; }));
                event.preventDefault();
            }
            if (shortcut && event.code === 'KeyR') {
                if (selectedLineItems.length) {
                    var payload = {
                        quoted_products: selectedLineItems,
                    };
                    QuoteStore.recalculatePrices(payload).catch(function (err) {
                        notification.error({
                            key: 'form-errors',
                            duration: 8,
                            message: 'One of the line items has an error',
                            description: React.createElement(ErrorRender, { error: err }),
                            placement: 'top',
                            maxCount: 1,
                        });
                    });
                }
            }
            if (shortcut && event.code === 'KeyE') {
                if (selectedLineItems.length) {
                    setShowBulkEdit(true);
                }
                event.preventDefault();
            }
            if (event.code === 'Escape') {
                setSelectedLineItems([]);
                event.preventDefault();
            }
        });
        window.scrollTo(0, 0);
    }, []);
    useEffect(function () {
        QuoteStore.clean();
        QuoteStore.getFinishes();
        QuoteStore.getCoatings();
        QuoteStore.getAdditionalRequirements();
        QuoteStore.getMaterials();
    }, []);
    useEffect(function () {
        QuoteStore.setQuote();
        QuoteStore.setCurrentUser({
            id: props.user.id,
            email: props.user.email,
            is_tester: props.user.is_tester,
            group: props.user.group,
        });
        if (id) {
            loadAllData(id);
        }
    }, [id]);
    /**
     * Save in local storage submitted requirements
     */
    useEffect(function () {
        if (quote === null || quote === void 0 ? void 0 : quote.custom_requirements) {
            //@ts-ignore
            setAdditionalRequirements(quote.custom_requirements);
        }
    }, [(_a = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _a === void 0 ? void 0 : _a.custom_requirements]);
    /**
     * Check if quote has a Design Engineer assigned
     */
    useEffect(function () {
        var _a, _b;
        if (quote === null || quote === void 0 ? void 0 : quote.id) {
            QuoteStore.getNotifications();
            QuoteStore.getRFQ((_a = quote === null || quote === void 0 ? void 0 : quote.rfq) === null || _a === void 0 ? void 0 : _a.id);
        }
        if ((quote === null || quote === void 0 ? void 0 : quote.id) && !((_b = quote === null || quote === void 0 ? void 0 : quote.design_engineer) === null || _b === void 0 ? void 0 : _b.id) && !quote.completed) {
            Modal.confirm({
                title: 'Confirm',
                content: "Set ".concat(props.user.email, " as the Design Engineer? "),
                okText: 'Yes',
                cancelText: 'No',
                onOk: function () {
                    QuoteStore.updateQuote(quote === null || quote === void 0 ? void 0 : quote.id, {
                        design_engineer: props.user.id,
                    });
                },
            });
        }
    }, [quote === null || quote === void 0 ? void 0 : quote.id]);
    /**
     * Load Related Account
     */
    useEffect(function () {
        var _a;
        if ((_a = quote === null || quote === void 0 ? void 0 : quote.account) === null || _a === void 0 ? void 0 : _a.id) {
            AccountStore.getAccount(quote === null || quote === void 0 ? void 0 : quote.account.id);
        }
    }, [(_b = quote === null || quote === void 0 ? void 0 : quote.account) === null || _b === void 0 ? void 0 : _b.id]);
    useEffect(function () {
        if (quote && quote.stage == 'Completed') {
            QuoteStore.checkIfExpiredQuote(quote === null || quote === void 0 ? void 0 : quote.id).then(function (data) {
                setIsExpired(data.is_expired_price);
            });
        }
    }, [quote === null || quote === void 0 ? void 0 : quote.stage]);
    if (isLoading) {
        return React.createElement(Spin, { spinning: true });
    }
    if (!(quote === null || quote === void 0 ? void 0 : quote.id)) {
        return React.createElement("div", null, "Not found");
    }
    var line_items = QuoteStore.masterProductsByPosition;
    var account_detail = AccountStore.account;
    return (React.createElement(React.Fragment, null,
        React.createElement(Affix, { className: '', offsetTop: 0 },
            React.createElement(QuoteHeader, { quote: quote, user: {
                    id: props.user.id,
                    email: props.user.email,
                    display_name: props.user.display_name,
                }, isExpired: isExpired, handleShowQuoteEdit: function () { return setShowEditForm(true); }, handleQuoteToolLayoutVersionChange: function (version) { return setVersion(version); }, account: account_detail })),
        React.createElement("div", { className: 'quote-information flex flex-col' },
            account_detail && (React.createElement(React.Fragment, null,
                React.createElement(AccountInfo, { account: account_detail }))),
            !QuoteStore.inProgress && quote.editable && (React.createElement("div", { className: 'p-5' },
                React.createElement(Alert, { message: React.createElement("div", null,
                        "You need to switch to ",
                        React.createElement("span", { className: 'font-bold' }, "In Progress"),
                        " to modify this quote",
                        React.createElement(Button, { onClick: function () {
                                var payload = {
                                    stage: 'In Progress',
                                };
                                QuoteStore.updateQuote(quote.id, payload);
                            }, className: 'ml-4', type: 'default' }, "Switch to In Progress")), type: "warning" }))),
            !(quote === null || quote === void 0 ? void 0 : quote.design_engineer) && (React.createElement(Form.Item, { label: "Quote Author", className: "text-center" },
                React.createElement(Button, { type: "link", onClick: function () {
                        QuoteStore.updateQuote(quote === null || quote === void 0 ? void 0 : quote.id, {
                            design_engineer: props.user.id,
                        });
                    } },
                    "Set ",
                    props.user.email,
                    " as the Design Engineer?",
                    ' '))),
            React.createElement(DeletionAlert, { accountDeleted: !(quote === null || quote === void 0 ? void 0 : quote.account) || ((_c = quote === null || quote === void 0 ? void 0 : quote.account) === null || _c === void 0 ? void 0 : _c.deleted), contactDeleted: !(quote === null || quote === void 0 ? void 0 : quote.contact) || ((_d = quote === null || quote === void 0 ? void 0 : quote.contact) === null || _d === void 0 ? void 0 : _d.deleted) }),
            (!(quote === null || quote === void 0 ? void 0 : quote.account) || ((_e = quote.account) === null || _e === void 0 ? void 0 : _e.deleted)) && (React.createElement("div", { className: 'flex justify-center' },
                React.createElement(Alert, { message: React.createElement("div", null,
                        ' ',
                        React.createElement(Form.Item, { label: 'Select a new Account', style: {
                                width: 200,
                            }, help: React.createElement("div", null,
                                "If you don't see the account?\u00A0",
                                React.createElement("a", { target: "_blank", href: "".concat(ZOHO_BASE_URL, "/Accounts/create"), rel: "noreferrer" }, "Create here")) },
                            React.createElement(GenericSelect, { recordType: "Account", key: 'Account', 
                                //@ts-ignore
                                onSelect: function (value) {
                                    if (value === null || value === void 0 ? void 0 : value.key) {
                                        setAccount(value);
                                        QuoteStore.updateAccountsInQuoteAndRelatedItems(quote === null || quote === void 0 ? void 0 : quote.id, {
                                            account: value === null || value === void 0 ? void 0 : value.key,
                                        });
                                    }
                                }, value: account }))), type: "error" }))),
            (!(quote === null || quote === void 0 ? void 0 : quote.contact) || ((_f = quote.contact) === null || _f === void 0 ? void 0 : _f.deleted)) && quote.account && (React.createElement("div", { className: "text-center  flex align-center" },
                React.createElement(Form.Item, { label: "Select a new Contact of ".concat(quote.account.name), key: 'Contact', style: {
                        width: 200,
                    } },
                    React.createElement(GenericSelect, { key: 'contact', filters: {
                            account: quote.account.id,
                        }, recordType: "Contact", 
                        //@ts-ignore
                        onSelect: function (value) {
                            if (value === null || value === void 0 ? void 0 : value.key) {
                                QuoteStore.updateAccountsInQuoteAndRelatedItems(quote === null || quote === void 0 ? void 0 : quote.id, {
                                    contact: value === null || value === void 0 ? void 0 : value.key,
                                });
                            }
                        }, value: account })))),
            reordering && React.createElement(ReorderMasterProducts, { quote_id: quote.id, handleClose: function () { return setReordering(false); } }),
            reordering_app_cost && (React.createElement(ApplicationCostItemsReorder, { quote_id: quote.id, handleClose: function () { return setReorderingAppCost(false); } }))),
        React.createElement(QuoteToolBar, { quote: quote, selectedLineItems: selectedLineItems, handleSelectAll: function () { return setSelectedLineItems(QuoteStore.lineItems.map(function (item) { return item.id; })); }, handleSelectNone: function () { return setSelectedLineItems([]); }, handleShowBulkEdit: function () { return setShowBulkEdit(true); }, handleShowPreview: function () { return setPreviewQuote(true); }, handleShowEdit: function () { return setShowEditForm(true); }, handleShowReorder: function () { return setReordering(true); }, handleShowReArrangeFiles: setReArrangeFiles, handleShowMessages: function () {
                SetShowQuoteNotificationsDrawer(true);
            } }),
        version === 1 ? (React.createElement("div", { className: 'quote-classic-view flex flex-col  shadow' },
            React.createElement(LayoutV1, { quote: quote, line_items: line_items, account_detail: account_detail, user: {
                    id: props.user.id,
                    email: props.user.email,
                    group: props.user.group,
                }, setPreviewQuote: function () { return setPreviewQuote(true); }, setShowBulkEdit: function () { return setShowBulkEdit(true); }, selectedLineItems: selectedLineItems, setSelectedLineItems: setSelectedLineItems, additional_requirements: additional_requirements, setAdditionalRequirements: setAdditionalRequirements, setReorderingAppCost: setReorderingAppCost }))) : (React.createElement("div", { className: 'quote-one-view flex flex-col  shadow', style: {
                minHeight: '89vh',
            } },
            React.createElement("div", { className: "quote-top shadow", style: {
                    height: '97%',
                } },
                React.createElement(LayoutV2, { master_products: line_items, user: {
                        id: props.user.id,
                        email: props.user.email,
                        group: props.user.group,
                    }, rfq_id: (_g = quote === null || quote === void 0 ? void 0 : quote.rfq) === null || _g === void 0 ? void 0 : _g.id, selectedLineItems: selectedLineItems, setPreviewQuote: setPreviewQuote, quote: quote, additional_requirements: additional_requirements, handleSelectLineItems: setSelectedLineItems, account: account_detail, setAdditionalRequirements: setAdditionalRequirements, setShowQuoteNotificationDrawer: function () { return SetShowQuoteNotificationsDrawer(true); }, setReorderingAppCost: setReorderingAppCost })),
            React.createElement("div", { className: "quote-bottom  flex flex-row items-center justify-center", style: {
                    height: '3%',
                } },
                React.createElement("div", { className: 'quote-status m-auto', style: {
                        width: '70%',
                        textAlign: 'center',
                    } },
                    React.createElement(QuoteStatus, { quote: quote, user: props.user, isQuoteLoading: QuoteStore.loading })),
                React.createElement("div", { className: 'quote-actions m-auto w-1/2', style: {
                        width: '30%',
                    } },
                    React.createElement(QuoteActions, { loading: QuoteStore.loading, quote: quote, handlePreviewQuote: function () { return setPreviewQuote(true); } }))))),
        previewQuote && React.createElement(QuotePreview, { quote_id: quote.id, handleClose: function () { return setPreviewQuote(false); } }),
        showEditForm && (React.createElement(Modal, { visible: true, title: "Edit Quote", onCancel: function () { return setShowEditForm(false); }, footer: null },
            React.createElement("div", { className: "mb-10" },
                React.createElement(QuoteEditForm, { quote: quote, handleSave: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, QuoteStore.updateQuote(quote.id, payload)];
                                case 1:
                                    _a.sent();
                                    return [4 /*yield*/, QuoteStore.getQuote(quote.id)];
                                case 2:
                                    _a.sent();
                                    setShowEditForm(false);
                                    return [2 /*return*/];
                            }
                        });
                    }); } })))),
        showBulkEdit && (React.createElement(Modal, { visible: true, title: React.createElement("div", null,
                "Bulk Edit: Editing ",
                React.createElement("span", { className: 'font-bold text-xl' },
                    " ",
                    selectedLineItems.length,
                    " "),
                ' ',
                "lines in the quote"), onCancel: function () { return setShowBulkEdit(false); }, width: '800px', centered: true, footer: [] },
            React.createElement("div", { className: "mb-10" },
                React.createElement(LineItemsBulkEditForm, { handleSave: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, QuoteStore.updateMultipleLineItems({
                                        quoted_products: selectedLineItems,
                                        data: payload,
                                    })];
                                case 1:
                                    _a.sent();
                                    setShowBulkEdit(false);
                                    return [2 /*return*/];
                            }
                        });
                    }); } })))),
        React.createElement(Drawer, { title: "Notifications", placement: "right", closable: true, width: 1200, height: 500, onClose: function () { return SetShowQuoteNotificationsDrawer(false); }, visible: showQuoteNotificationsDrawer }, showQuoteNotificationsDrawer && React.createElement(QuoteNotifications, { height: 600 })),
        React.createElement(Drawer, { title: React.createElement("b", null, "Re-arrange files, from line items"), placement: "left", closable: true, width: 1200, height: 500, onClose: function () { return setReArrangeFiles(false); }, visible: reArrangeFiles },
            React.createElement(ReArrangeFiles, { quote_id: quote.id })),
        React.createElement(Row, { className: 'errors' },
            React.createElement(ErrorRender, { error: QuoteStore.error }))));
};
export default observer(QuoteTool);
