import React from 'react';
import { useParams } from 'react-router-dom';

import { OpenTickets } from './OpenTickets';
import { ClosedTickets } from './ClosedTickets';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { useQueryParameters } from '@partsbadger/utils';
import { observer } from 'mobx-react';

type Params = {
    zoho_id: string;
};

interface IProps {
    message_type?: string;
}

const TicketMainPage = (props: IProps) => {
    let { zoho_id } = useParams<Params>();
    zoho_id = zoho_id ? zoho_id : SalesOrderStore.order?.zoho_id ?? '';

    const query_params = useQueryParameters();

    const getMessageType = query_params.get('type') ? query_params.get('type') : props?.message_type;

    const getTitle = () => {
        switch (getMessageType) {
            case 'sales-support':
                return 'Ticket Support System';
            case 'production-sales':
                return 'Support Ticket System for Sales Requests';
            default:
                return 'Ticket Support System';
        }
    };

    return (
        <div className="flex flex-col p-1">
            <div className="flex flex-col items-center">
                <p className="mt-6 text-lg font-bold">{getTitle()}</p>

                <div className="pt-4 w-full shadow-lg border-2 border-gray-300 mt-2">
                    <OpenTickets zoho_id={zoho_id} message_type={getMessageType} />
                </div>

                <div className="pt-4 w-full shadow-lg border-2 border-gray-300 mt-8">
                    <ClosedTickets zoho_id={zoho_id} message_type={getMessageType} />
                </div>
            </div>
        </div>
    );
};

export default observer(TicketMainPage);
