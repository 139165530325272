import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { DateRender } from '@partsbadger/library';
import { Card, Col, Dropdown, Menu, Space, Table, Typography, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { useQueryParameters, ZOHO_BASE_URL } from '@partsbadger/utils';
import SendToZohoButton from '../../components/Buttons/SendToZohoButton';
import { PaginationComponent } from '../../components/Inputs/Pagination';
import { AccountStore, IAccountList } from '../../stores/AccountStore';
import Render from '../RenderPage';
import { ColumnProps } from 'antd/es/table';
import { DownOutlined, FilePdfOutlined } from '@ant-design/icons';
import RenderFilter from '../RenderFilters';
import RenderSearch from '../RenderSearch';
import ActionMenu from '../../components/TableActions/TableActions';

const AccountListPage = () => {
    const history = useHistory();

    const searchParams = useQueryParameters();

    const loadData = () => {
        const params = {
            page: parseInt(searchParams.get('page') ?? '1'),
            search: searchParams.get('search') ?? '',
        };
        AccountStore.getAll(params);
    };

    useEffect(() => {
        loadData();
    }, [location.search]);

    const columns: ColumnProps<IAccountList>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link onClick={() => history.push(`/accounts/${record.id}/`)}>
                            {record.name}
                        </Typography.Link>
                    </div>
                );
            },
        },

        {
            title: 'Created',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.owner.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                // const menu = (
                //     <Menu>
                //         {/* Download 2D File (PDF) */}
                //         <Menu.Item
                //             key="download-pdf"
                //             icon={<FilePdfOutlined />}
                //             onClick={() => {
                //                 history.push('/accounts/' + record.id + '/');
                //             }}
                //         >
                //             View Account
                //         </Menu.Item>
                //
                //         <Menu.Item
                //             key="download-pdf"
                //             icon={<FilePdfOutlined />}
                //             onClick={() => {
                //                 history.push('/accounts/' + record.id + '/');
                //             }}
                //         >
                //             {record.zoho_id ? (
                //                 <a
                //                     style={{
                //                         fontSize: 12,
                //                         marginLeft: 4,
                //                     }}
                //                     target={'_blank'}
                //                     rel="noreferrer"
                //                     href={`${ZOHO_BASE_URL}/Accounts/${record.zoho_id}`}
                //                 >
                //                     View In Zoho
                //                 </a>
                //             ) : (
                //                 <SendToZohoButton
                //                     id={record.id}
                //                     module={'accounts'}
                //                     onFinish={() => {
                //                         loadData();
                //                     }}
                //                 />
                //             )}
                //         </Menu.Item>
                //     </Menu>
                // );
                //
                // return (
                //     <Space>
                //         <Dropdown overlay={menu} trigger={['click']}>
                //             <Typography.Link>
                //                 <Space>
                //                     Actions
                //                     <DownOutlined />
                //                 </Space>
                //             </Typography.Link>
                //         </Dropdown>
                //     </Space>
                // );
                const actions = [
                    {
                        key: 'view-details',
                        icon: <FilePdfOutlined />,
                        text: 'View Details',
                        link: `/accounts/${record.id}`,
                    },
                    {
                        key: 'view-in-zoho',
                        icon: <FilePdfOutlined />,
                        text: 'View In Zoho',
                        link: `${ZOHO_BASE_URL}/Accounts/${record.zoho_id}`,
                    },
                ];

                return <ActionMenu actions={actions} />;
            },
        },
    ];

    const { entities, isLoading } = AccountStore;

    return (
        <Render
            title="Accounts"
            // sideBarFilter={<RenderFilter />}
        >
            <div
                style={{
                    margin: 'auto',
                }}
            >
                <Card>
                    <Col span={24}>
                        <RenderSearch />
                    </Col>
                </Card>

                <Card>
                    <Col span={24}>
                        <Table<IAccountList>
                            rowKey={'id'}
                            dataSource={entities}
                            loading={isLoading}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: 'auto' }}
                            footer={() => {
                                return (
                                    <PaginationComponent
                                        current={AccountStore.pagination.current}
                                        defaultPageSize={AccountStore.pagination.defaultPageSize}
                                        pageSize={AccountStore.pagination.pageSize}
                                        total={AccountStore.pagination.total}
                                    />
                                );
                            }}
                        ></Table>
                    </Col>
                </Card>
            </div>
        </Render>
    );
};

export default observer(AccountListPage);
